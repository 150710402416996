import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '@/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { colors, device } from '@/layout/GlobalStyles';
import { Button } from '@/layout/StyledComponents';
import { graphql } from 'gatsby';

const StyledCovid = styled.div`
    padding: 10em 1em 4em;
    a {
        color: ${colors.blueLight};
    }
    p {
        margin: 1em 0em;
    }
    button {
        margin: 2em 0em;
    }
    @media ${device.tablet} {
        margin: 0 auto;
        max-width: 75vw;
        padding: 8em 1em 2em;
        button {
            margin: 4em auto 2em;
        }
    }
    @media ${device.laptop} {
        margin: 0 auto;
        max-width: 75vw;
        padding: 4em 1em 0em;
        button {
            margin: 4em 0em 2em;
        }
    }
`;

const Covid: Page = ({ data }) => {
    const heroImage = data.allFile.edges[0].node.childImageSharp.gatsbyImageData;
    return (
        <main>
            <DefaultLayout
                title="Covid"
                heroImage={heroImage}
                heroText="Read and learn some general facts about Covid-19 as well as any updated from out team"
                // heroText={data.mdx.frontmatter.heroText}
            >
                <Seo
                    title="Health Covid Information | Bloom La Vie Health"
                    description="The Front Line COVID-19 Critical Care Alliance (FLCCC) publishes updates surrounding the most effective prevention & early treatment protocols to counter COVID-19."
                />
                <StyledCovid>
                    <div>
                        <StaticImage
                            src="../images/nu-logo.png"
                            alt="flcc logo"
                            placeholder="none"
                            quality={100}
                        />
                        <p>
                            The Front Line COVID-19 Critical Care Alliance (FLCCC) publishes updates
                            surrounding the most effective prevention & early treatment protocols to
                            counter COVID-19.{' '}
                        </p>
                    </div>
                    <p>
                        {' '}
                        If you would like a consultation with Dr. Nedeau regarding these guidelines,
                        Bloom La Vie Health is offering a telemedicine call to discuss any needs or
                        concerns surrounding COVID-19 for $150.{' '}
                    </p>
                    <p>
                        Click the link below to pre-pay and a member of the Bloom La Vie Health
                        staff will contact you to schedule an appointment.
                    </p>
                    <Button color="blue-light">
                        <a
                            target="__blank"
                            rel="noopener noreferrer"
                            href="https://covid19criticalcare.com/"
                        >
                            Schedule Now
                        </a>
                    </Button>
                </StyledCovid>
            </DefaultLayout>
        </main>
    );
};

export default Covid;

export const query = graphql`
    query CovidHeroPicture {
        allFile(filter: { relativePath: { eq: "covid.jpg" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                }
            }
        }
    }
`;
